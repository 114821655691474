/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

async function requestData(token, id) {
  return fetch(process.env.REACT_APP_API_URL + '/app/request/' + id,
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

function renderStatus(status) {
  switch(status) {
    case 'ERROR':
      return <span className='uk-text-danger'>ERROR</span>;
    case 'PENDING':
      return <span className='uk-text-warning'>PENDING</span>;         
    default:
      return <span className='uk-text-success'>{status}</span>; 
  }
}

export default function Request(props) {
  const [request, setRequest] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();

  
  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await requestData(props.token, id);
      console.log(data);
      if (!data || data.errors) {
        await localStorage.removeItem("token");
        await localStorage.removeItem("role");
        window.location.replace('/login?from=' + window.location.pathname);
        return
      }
      if (!loaded) {
        setRequest(data.request);
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, id])

  return(
    <div>
      {error ?
        <div className="uk-alert-danger" uk-alert="">
            <p>Post error!</p>
        </div>
      : null}
      {request ? 
        <div>
          <a href={'/payload/' + request.payload_id + '/'}>
            <button className="uk-button uk-button-default"><span uk-icon="arrow-left"></span> payload</button>
          </a>
          <h2>Post: {request.id}</h2>
          <div className="uk-card uk-card-default">
            <div className="uk-card-header">
                <h3 className="uk-card-title">{renderStatus(request.status)}</h3>
            </div>
            <div className="uk-card-body">
              <p>Location: <a href={'/company/' + request.company_id + '/client/' + request.client_id + '/location/' + request.location_id + '/'}>{request.location_name}</a></p>
              <p>Sent to GatherUp: {request.status !== 'PENDING' ? new Date(request.sent_at).toLocaleString('en-US') : 'N/A'}</p>
              <p>Created: {new Date(request.createdAt).toLocaleString('en-US')}</p>
              <p>Updated: {new Date(request.updatedAt).toLocaleString('en-US')}</p>
              <ul uk-accordion="multiple: true">
                <li>
                    <a className="uk-accordion-title" href="#">Patient</a>
                    <div className="uk-accordion-content">
                      <p>Email address: {request.email_address}</p>
                      <p>Phone number: {request.phone_number}</p>
                      <p>First name: {request.patient_first_name}</p>
                      <p>Last name: {request.patient_last_name}</p>
                    </div>
                </li>
                <li>
                    <a className="uk-accordion-title" href="#">Response</a>
                    <div className="uk-accordion-content">
                      {
                        request.response === null ? <p>No response</p> : request.response === 'Skipped by configuration' ? <p>{request.response}</p> : 
                        Object.keys((request.response)).map((key, index) => {
                          return (<p key={index} ><b>{key}:</b> {request.response[key]}</p>)
                        })
                      }

                    </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      : null}
    </div>
  );
}
