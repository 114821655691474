/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Requests from '../Request/Requests';
import Stats from '../Stats/Stats';

async function payloadData(token, id) {
  return fetch(process.env.REACT_APP_API_URL + '/app/payload/' + id,
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

async function retryPayload(token, id) {
  return fetch(process.env.REACT_APP_API_URL + '/company/retry/' + id,
    {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

async function markAsSuccessfulPayload(token, id) {
  return fetch(process.env.REACT_APP_API_URL + '/company/mark/' + id,
    {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

function statusColor(status) {
  switch (status) {
    case 'CREATED':
      return '';
    case 'IGNORED':
      return 'uk-text-warning';
    case 'ERROR':
      return 'uk-text-danger';
    default:
      return '';
  }
}

function isVaildOMSKey(keys, key) {
  for (const k in keys) {
    if (keys[k] !== '' && keys[k] === key) {
      return true;
    }
  }
  return false;
}

export default function Payload(props) {
  const [payload, setPayload] = useState(false);
  const [keys, setKeys] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();
  const [markAsSuccessful, setMarkAsSuccessful] = useState(false);

  const handleMarkAsSuccessful = async e => {
    e.preventDefault();
    setMarkAsSuccessful(!markAsSuccessful);
  }

  const doMarkAsSuccessful = async e => {
    e.preventDefault();
    const response = await markAsSuccessfulPayload(props.token, id);
    if (!response || response.error) {
        setError(true);
        return
    }
    window.location.reload();
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await retryPayload(props.token, id);
    if (!response || response.error) {
        setError(true);
        return
    }
    window.location.replace('/company/' + payload.company_id  + '/');
  }
  
  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await payloadData(props.token, id);
      console.log(data);
      if (!data || data.errors) {
        await localStorage.removeItem("token");
        await localStorage.removeItem("role");
        window.location.replace('/login?from=' + window.location.pathname);
        return
      }
      if (!loaded) {
        setPayload(data.payload);
        setKeys(data.keys)
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, id])



  return(
    <div>
      {error ?
        <div className="uk-alert-danger" uk-alert="">
            <p>Payload error!</p>
        </div>
      : null}
      {payload ? 
        <div>
          <a href={'/company/' + payload.company_id  + '/'}>
            <button className="uk-button uk-button-default"><span uk-icon="arrow-left"></span> OMS</button>
          </a>
          <h2>Payload: {payload.id}</h2>
          <div className="uk-card uk-card-default">
            <div className="uk-card-header">
                <h3 className="uk-card-title uk-margin-remove uk-align-left">
                  {payload.status === 'ERROR' ? <span className='uk-text-danger'>ERROR</span> 
                  : <span className='uk-text-success'>SUCCESSFUL</span>}
                </h3>
                
                {payload.status === 'ERROR' ?
                  <div className="uk-margin-remove uk-align-right">
                    {markAsSuccessful ?
                      <div className="uk-margin-remove">
                        <p className="uk-text-center">Are you sure?</p>
                        <a className="uk-button uk-button-default white uk-margin-remove uk-text-success uk-margin-remove uk-align-left" onClick={doMarkAsSuccessful}>Yes</a>
                        <a className="uk-button uk-button-default white uk-margin-remove uk-text-danger uk-margin-remove uk-align-right" onClick={handleMarkAsSuccessful}>No</a>
                      </div>
                    : <a className="uk-button uk-button-default white uk-margin-remove uk-text-success" onClick={handleMarkAsSuccessful}>Mark as SUCCESSFUL</a>}
                  </div>
                : <div className="uk-margin-remove uk-align-right">{payload.fixed_on ? 'Fixed on ' + new Date(payload.fixed_on).toLocaleString('en-US')  : null}</div>}
            </div>
            <div className="uk-card-body">
              <ul uk-accordion="multiple: true">
              {payload.raw_request && payload.raw_request.records && payload.raw_request !== "raw_request" ? 
              <li>
                  {
                    payload.raw_request.params && payload.raw_request.params.clinic_id ? <h3>{'Clinic ID: ' + payload.raw_request.params.clinic_id}</h3> : null
                  }
                  {
                    payload.raw_request.filename ? <h3>{'File name: ' + payload.raw_request.filename}</h3> : null
                  }                  
                  <a className="uk-accordion-title" href="#">Original Records</a>
                  <div className="uk-accordion-content">
                  {Object.values((payload.raw_request.records)).map((value, index) => {
                      return (
                        <table key={index} className="uk-table uk-table-striped ">
                          <tbody>
                            <tr>
                              <td>{index + 1}</td>
                              {
                                Object.keys(value).map((key, i) => {
                                  if (isVaildOMSKey(keys, key)) {
                                    return (
                                      <td key={i} className={value[key] === "" || value[key] === null ? "uk-text-danger" : ""} >{key}:<br></br>{value[key]}</td>
                                    );
                                  }
                                  if (key === 'apptStaffList') {
                                    const first_name = value[key] ? value[key][0].firstName : null;
                                    const last_name = value[key] ? value[key][0].lastName : null;
                                    if (first_name && last_name) {
                                      return (
                                        <td key={i}>Provider Full name:<br></br>{first_name + ' ' + last_name}</td>
                                      );
                                    }
                                  }
                                  if (key === 'Provider First name') {
                                    const first_name = value['Provider First name'];
                                    const last_name = value['Provider Last name'];
                                    return (
                                      <td key={i}>Provider Full name:<br></br>{first_name + ' ' + last_name}</td>
                                    )
                                  }

                                  return null;
                                })
                              }
                            </tr>
                          </tbody>
                        </table>
                      )
                  })}
                 
                  </div>
                </li> : null}
                {payload.parsed_request && payload.parsed_request !== "parsed_request" ? 
              <li>
                  <a className="uk-accordion-title" href="#">Parsed Records</a>
                  <div className="uk-accordion-content">
                    <table className="uk-table uk-table-striped">
                      <thead>
                          <tr>
                              <th>Status</th>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Email Address</th>
                              <th>Phone Number</th>
                              <th>Location ID</th>
                          </tr>
                      </thead>
                      <tbody>
                        {Object.values((payload.parsed_request.data.records)).map((value, index) => {
                          return (
                            <tr key={index}>
                              <td className={statusColor(value.status)}>{value.status}</td>
                              <td>{value.patient_first_name}</td>
                              <td>{value.patient_last_name}</td>
                              <td>{value.email_address}</td>
                              <td>{value.phone_number}</td>
                              <td>{value.location_id}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </li> : null}                
                {payload.raw_request && payload.raw_request !== "raw_request" && props.role === 1 ? 
                <li>
                    <a className="uk-accordion-title" href="#">Raw request</a>
                    <div className="uk-accordion-content">
                        <p>{typeof payload.raw_request === 'string' ? payload.raw_request : JSON.stringify(payload.raw_request)}</p>
                    </div>
                </li> : null}
                {payload.parsed_request && payload.parsed_request !== "parsed_request" && props.role === 1  ? 
                <li>
                  <a className="uk-accordion-title" href="#">Parsed request</a>
                  <div className="uk-accordion-content">
                    <p>{JSON.stringify(payload.parsed_request)}</p>
                  </div>
                </li> : null}
                {payload.error_message && !payload.error_message.error_messages  && payload.error_message instanceof Array ?
                  <li>
                      <a className="uk-accordion-title" href="#">Error message</a>
                      <div className="uk-accordion-content">
                          <ul className="uk-list uk-list-striped">
                            {payload.error_message.map(function(message, i){
                              return (<li key={i}>{message}</li>)
                            })}
                          </ul>
                      </div>
                      <button className="uk-button uk-button-default uk-margin-medium-top uk-align-right" onClick={handleSubmit}><span uk-icon="refresh" style={{'verticalAlign': 'text-bottom'}}></span> Retry</button>
                  </li>
                : null}
                {payload.error_message && !payload.error_message.error_messages && typeof payload.error_message === 'string' ?
                  <li>
                      <a className="uk-accordion-title" href="#">Error message</a>
                      <div className="uk-accordion-content">
                        {payload.error_message}
                      </div>
                      <button className="uk-button uk-button-default uk-margin-medium-top uk-align-right" onClick={handleSubmit}>Retry</button>
                  </li>
                : null}                
                {payload.error_message && payload.error_message.error_messages ?
                  <li>
                      <a className="uk-accordion-title" href="#">Error message</a>
                      <div className="uk-accordion-content">
                        {payload.error_message.error_messages}
                      </div>
                      <button className="uk-button uk-button-default uk-margin-medium-top uk-align-right" onClick={handleSubmit}>Retry</button>
                  </li>
                : null}                
              </ul>
              <p>Created: {new Date(payload.createdAt).toLocaleString('en-US')}</p>
              <p>Updated: {new Date(payload.updatedAt).toLocaleString('en-US')}</p>
              {payload.parent_payload_id ?
                <a href={'/payload/' + payload.parent_payload_id + '/'}><button className="uk-button uk-button-default">Parent Payload</button></a>
              : null}
            </div>
          </div>
          <hr></hr>
          <Stats token={props.token} id={id} type={'payload'} period={'Total'}/>
          <hr></hr>
          <Requests payload={id} token={props.token} role={props.role}/>
        </div>
      : null}
    </div>
  );
}
