/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useParams } from "react-router-dom";

async function userDelete(token, id) {
  return fetch(process.env.REACT_APP_API_URL + '/crud/users/' + id,
    {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

export default function UserDelete(props) {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState(false);
  const { id } = useParams();

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await userDelete(props.token, id);
    if (!response || response.error) {
        setError(true);
        setResponse(response);
        return
    }
    setError(false);
    setSuccess(true);
    setResponse(response);
    console.log(response);
  }

  if (props.role !== 1) {   
    return(
      <div className="uk-alert-danger" uk-alert="">
        <p>Unauthorized user access!</p>
      </div>
    );
  }

  return(
    <div>
      {error ?
        <div className="uk-alert-danger" uk-alert="">
            <p>Delete user error!</p>
            {response ? <p>{response.error}</p> : null}
        </div>
      : null}
      {success ?
        <div className="uk-alert-success" uk-alert="">
            <a className="uk-alert-close" uk-close=""></a>
            <p>User deleted successfully</p>
            <a href={'/setup'}>
              <button className="uk-button uk-button-default"><span uk-icon="arrow-left"></span> setup</button>
            </a>
        </div>
      : <div>
          <a href={'../' + id + '/'}>
            <button className="uk-button uk-button-default"><span uk-icon="arrow-left"></span> user</button>
          </a>
          <div className="uk-card uk-card-default uk-margin-top">
            <div className="uk-card-header">
                <h3 className="uk-card-title">Delete User</h3>
            </div>
            <div className="uk-card-body">
            {id !== '1' ?
            <form className="uk-form-horizontal" uk-grid="" onSubmit={handleSubmit}>
              <p>Are you sure you want to <span className="uk-alert-danger">DELETE</span> the <a href={'../' + id + '/'}>user</a>?</p>
              <div className="uk-width-1-1@s uk-width-1-1@m">
                <button className="uk-button uk-button-default" type="submit">Delete</button>
              </div>
            </form>
            : <p>Can't <span className="uk-alert-danger">DELETE</span> this user</p>}
            </div>
          </div>
        </div>
      }
    </div>
  );
}
