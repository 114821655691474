/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './Company.css';
import Payloads from '../Payload/Payloads';
import Locations from '../Location/Locations';
import Clients from '../Client/Clients';
import Stats from '../Stats/Stats';
import LastPayload from '../Stats/LastPayload';

async function companyData(token, id) {
  return fetch(process.env.REACT_APP_API_URL + '/app/company/' + id,
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

export default function Company(props) {
  const [company, setCompany] = useState(false);
  const [stats, setStats] = useState(false);
  const [locations, setLocations] = useState(false);
  const [clients, setClients] = useState(false);
  const [payloads, setPayloads] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();

  
  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await companyData(props.token, id);
      console.log(data);
      if (!data || data.errors) {
        await localStorage.removeItem("token");
        await localStorage.removeItem("role");
        window.location.replace('/login?from=' + window.location.pathname);
        return
      }
      if (!loaded) {
        setCompany(data.company);
        setFeatured(data.featured);
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, id])

  function showStats(e) {
    setStats(!stats);
  }

  function showLocations(e) {
    setLocations(!locations);
  }


  function showClients(e) {
    setClients(!clients);
  }

  function showPayloads(e) {
    setPayloads(!payloads);
  }

  return(
    <div>
      {error ?
        <div className="uk-alert-danger" uk-alert="">
            <p>Company error!</p>
        </div> 
      : null}
      {company ? 
        <div>
          <a href={'/dashboard/'}>
            <button className="uk-button uk-button-default"><span uk-icon="arrow-left"></span> dashboard</button>
          </a>
          
          <h2>OMS: {company}</h2>
          <div className="uk-text-center uk-margin-small-bottom" style={{backgroundColor: "#e3f1ff"}} uk-sticky="offset: 80">
          <div className=" uk-button-group">
            <div><a className="uk-button uk-button-default white" data-text="Stats" onClick={showStats} href="#stats" uk-scroll="">{stats ? 'Hide' : 'Show'} Stats</a></div>
            <div><a className="uk-button uk-button-default white" data-text="Locations" onClick={showLocations} href="#locations" uk-scroll="">{locations ? 'Hide' : 'Show'} Locations</a></div>
            <div><a className="uk-button uk-button-default white" data-text="Clients" onClick={showClients} href="#clients" uk-scroll="">{clients ? 'Hide' : 'Show'} Clients</a></div>
            <div><a className="uk-button uk-button-default white" data-text="Payloads" onClick={showPayloads} href="#payloads" uk-scroll="">{payloads ? 'Hide' : 'Show'} Payloads</a></div>      
          </div>
          </div>

          {stats ?
            <div id="stats">
              <hr></hr>
              <div className={"uk-grid-match uk-child-width-1-1@s uk-child-width-1-" + (featured ? '4@m' : '2@m')} uk-grid="">
                <div><Stats token={props.token} id={id} type={'company'} period={'Daily'}/></div>
                <hr className="uk-hidden@m"></hr>
                <div><Stats token={props.token} id={id} type={'company'} period={'Weekly'}/></div>
                <hr className="uk-hidden@m"></hr>
                <div><Stats token={props.token} id={id} type={'company'} period={'Monthly'}/></div>
                <hr className="uk-hidden@m"></hr>
                <div><Stats token={props.token} id={id} type={'company'} period={'Total'}/></div>
                <hr className="uk-hidden@m"></hr>
                {featured ? <div><LastPayload featured={featured}/></div> : null}
              </div>
            </div>
             : null
          }
          {locations ?
            <div id="locations">
              <hr></hr>
              <Locations company={id} token={props.token} role={props.role}/>
            </div>
             : null
          }
          {clients ?
            <div id="clients">
              <hr></hr>
              <Clients company={id} token={props.token} role={props.role}/>
            </div>
             : null
          }
          {payloads ?
            <div id="payloads">
              <hr></hr>
              <Payloads company={id} token={props.token} role={props.role}/>
            </div>
             : null
          }
        </div>
      : null}
    </div>
  );
}
