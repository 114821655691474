import { useState } from 'react';

export default function useLogin() {
  const getRole = () => {
    const roleString = localStorage.getItem('role');
    const userRole = JSON.parse(roleString);
    return userRole
  };
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken
  }; 

  const [role, setRole] = useState(getRole());
  const [token, setToken] = useState(getToken());

  const saveRole = userRole => {
    localStorage.setItem('role', JSON.stringify(userRole));
    setRole(userRole);
  };
  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };


  return {
    setRole: saveRole,
    role,
    setToken: saveToken,
    token
  }
}