/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Pagination from '../Pagination/Pagination';
const LIMIT_PER_PAGE = 10;

async function clientsData(token, page = 1, limit = LIMIT_PER_PAGE, company_id = '', clinic_id = '', search = '') {
  return fetch(process.env.REACT_APP_API_URL + '/app/clients/?' + 
    new URLSearchParams({
      'page': page,
      'limit': limit,
      'company_id': company_id,
      'clinic_id': clinic_id,
      'search': search,
    }),
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

function renderRate(value) {
  if (!value && value !== 0) {
    return <span>N/A</span>;
  }
    
  switch(true) {
    case (value < 50):
      return <span className='uk-text-danger'>{value}%</span>;
    case (value < 75):
      return <span className='uk-text-warning'>{value}%</span>;         
    default:
      return <span className='uk-text-success'>{value}%</span>; 
  }
}

export default function Clients(props) {
  const [clients, setClients] = useState(false);
  const [clientID, setClientID] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit] = useState(LIMIT_PER_PAGE);
  const [error, setError] = useState(false);
  
  const handleSearchChange = async e => {
    setPage(1);
    setSearch(e.target.value);
  }

  const handleClientIDChange = async e => {
    setPage(1);
    setClientID(e.target.value);
  }

  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await clientsData(props.token, page, limit, props.company, clientID, search);
      console.log(data);
      if (!data || data.errors) {
        setError(true)
        return
      }
      if (!loaded) {
        setClients(data.clients.data);
        setPage(data.clients.meta.current_page)
        setLastPage(data.clients.meta.last_page)
        setTotal(data.clients.meta.total)
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, page, limit, props.company, clientID, search])

  return(
    <div id="clients" className="uk-card uk-card-default">
      <div className="uk-card-header">
        <div uk-grid="">
          <div className="uk-width-1-1@s uk-width-expand@m">
            <h3 className="uk-card-title">Clients ({total})</h3>
          </div>
          <div className="uk-width-1-1@s uk-width-1-6@m">
            <input className="uk-input" id="clinic_id" type="text" placeholder="Clinic ID" value={clientID} onChange={handleClientIDChange}/>
          </div>
          <div className="uk-width-1-1@s uk-width-1-3@m">
            <input className="uk-input" id="search" type="text" placeholder="Client name" value={search} onChange={handleSearchChange}/>
          </div>
        </div>
      </div>

      {error ? 
        <div className="uk-alert-danger" uk-alert=""><p>Clients error!</p></div>
      : null}

      {clients ? 
        <div className="uk-card-body">

          {total ?  
            <table className="uk-table uk-table-striped uk-table-hover">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th className="uk-table-expand">Clinic ID</th>
                        <th className="uk-table-expand">Name</th>
                        <th className="uk-table-expand">Locations</th>
                        <th className="uk-table-expand">GatherUp posts ( D | W | M | T )</th>
                        <th className="uk-table-expand">Success Rates ( D | W | M | T )</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                  {(clients).map((client) =>
                      <tr key={client.id}>
                        <td><a href={'/company/' + props.company + '/client/' + client.id + '/'}>{client.id}</a></td>
                        <td>{client.clinic_id}</td>
                        <td>{client.name}</td>
                        <td>{client.locations_count}</td>
                        <td>{client.stats_daily.total_posts} | {client.stats_weekly.total_posts} | {client.stats_monthly.total_posts} | {client.stats_total.total_posts}</td>
                        <td>{renderRate(client.stats_daily.post_success_rate)} | {renderRate(client.stats_weekly.post_success_rate)} | {renderRate(client.stats_monthly.post_success_rate)} | {renderRate(client.stats_total.post_success_rate)}</td>
                        <td><a href={'/company/' + props.company + '/client/' + client.id + '/'}><span uk-icon="pencil"></span></a></td>
                      </tr>
                  )}
                </tbody>
            </table> 
          : <p>No results</p>}

          <Pagination page={page} lastPage={lastPage} total={total} limit={limit} setPage={setPage}/>

          {props.company && (props.role === 1 || props.role === 2) ? <a className="uk-button uk-button-default white uk-margin-remove uk-text-success" href={'client/add'}>ADD</a> : null}
        </div>
      : null}
    </div>
  );
}
