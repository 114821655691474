/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Configs from './Configs';
import Users from '../User/Users';
import Roles from '../Role/Roles';

export default function Setup(props) {
  const [setup] = useState(true);
  const [error] = useState(false);
  if (props.role !== 1) {
    return (
      <div className="uk-alert-danger" uk-alert="">
        <a className="uk-alert-close" uk-close=""></a>
        <p>Access denied!</p>
      </div>
    );
  }
  return(
    <div>
      {error ?
        <div className="uk-alert-danger" uk-alert="">
            <p>Security Settings error!</p>
        </div>
      : null}
      {setup ? 
        <div>
          <h2>Setup</h2>
          <Configs token={props.token} role={props.role}/>
          <hr></hr>
          <Users token={props.token} role={props.role}/>
          <hr></hr>
          <Roles token={props.token} role={props.role}/>
        </div>
      : null}
    </div>
  );
}
