/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useParams } from "react-router-dom";

async function sendData(token, data) {
  return fetch(process.env.REACT_APP_API_URL + '/crud/users',
    {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

function showHide() {
  const password = document.getElementById("password");
  if (password.type === 'text') {
    password.type = 'password';
  } else {
    password.type = 'text';
  }
}

export default function UserAdd(props) {
  const { role_id } = useParams();
  const [formData, setFormData] = useState({
    'name': '',
    'email': '',
    'password': '',
    'role_id': role_id,
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState(false);

  const handleInputChange = async e => {
    console.log(e.target);
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    console.log(formData);
    const response = await sendData(props.token, formData);
    if (!response) {
        setError(true);
        return
    }
    if (response.error) {
      setError(true);
      setResponse(response);
      console.log(response);
      return
    }
    setError(false); 
    setSuccess(true);
    setResponse(response);
    console.log(response);
  }

  if (props.role !== 1) {   
    return(
      <div className="uk-alert-danger" uk-alert="">
        <p>Unauthorized user access!</p>
      </div>
    );
  }

  return(
    <div>
      {error ?
        <div className="uk-alert-danger" uk-alert="">
            <p>Add user error!</p>
            {response ? <p>{response.error}</p> : null}
        </div>
      : null}
      {success ?
        <div className="uk-alert-success" uk-alert="">
            <a className="uk-alert-close" uk-close=""></a>
            <p>User added successfully</p>
            <a href={'../' + response.user.id + '/'}>See user</a>
        </div>
      : null}      
        <a href={'/role/' + role_id}>
          <button className="uk-button uk-button-default"><span uk-icon="arrow-left"></span> role</button>
        </a>
        <div className="uk-card uk-card-default uk-margin-top">
          <div className="uk-card-header">
              <h3 className="uk-card-title">New User</h3>
          </div>
          <div className="uk-card-body">
            <form className="uk-form-horizontal" uk-grid="" onSubmit={handleSubmit}>

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="user_name">Name</label>
                <div className="uk-form-controls">
                    <input className="uk-input" id="name" type="text" placeholder="Name" value={formData['name']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-2@m uk-inline">
                <label className="uk-form-label" htmlFor="password">Password</label>
                <div className="uk-form-controls">
                <a className="uk-form-icon uk-form-icon-flip" uk-icon="lock" onClick={showHide}></a>
                  <input className="uk-input" id="password" type="password" placeholder="Password" value={formData['password']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="email">Email</label>
                <div className="uk-form-controls">
                    <input className="uk-input" id="email" type="email" placeholder="Email" value={formData['email']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="role_id">Role</label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="role_id" value={formData['role_id']} onChange={handleInputChange}>
                    <option value="">Please select...</option>
                    <option value="1">SuperAdmin</option>
                    <option value="2">Admin</option>
                    <option value="3">Viewer</option>
                  </select>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-1@m">
                <button className="uk-button uk-button-default" type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
    </div>
  );
}
