/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

async function statsData(token, id, type, period = 'total') {
  return fetch(process.env.REACT_APP_API_URL + '/app/stats/?' + 
    new URLSearchParams({
      'id': id,
      'type': type,
      'period': period,
    }),
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}


function renderStat(key, value) {
  if(!value && value !== 0) {
    return;
  }
  const new_key = key.split('_').join(' ');
  if (new_key.includes('rate')) {
    return (
      <li key={key}>{new_key.toUpperCase()} : {renderRate(parseInt(value))}</li>
    );
  }
  return (
    <li key={key}>{new_key.toUpperCase()} : {value.toString()}</li>
  );
}

function renderRate(value) {
  if (!value && value !== 0) {
    return <span>N/A</span>;
  }
  
  switch(true) {
    case (value < 50):
      return <span className='uk-text-danger'>{value}%</span>;
    case (value < 75):
      return <span className='uk-text-warning'>{value}%</span>;         
    default:
      return <span className='uk-text-success'>{value}%</span>; 
  }
}

export default function Stats(props) {
  const [stats, setStats] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await statsData(props.token, props.id, props.type, props.period);
      console.log(data);
      if (!data || data.errors) {
        setError(true)
        return
      }
      if (!loaded) {
        setStats(data);
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, props.id, props.type, props.period])

  return(
    <div className="uk-card uk-card-default">
      <div className="uk-card-header">
          <h3 className="uk-card-title">{props.period ? props.period + ' stats' : 'Stats'}</h3>
      </div>
      <div className="uk-card-body">
      {error ? 
        <div className="uk-alert-danger" uk-alert=""><p>Stats error!</p></div>
      : 
        <ul>
        {
          Object.entries(stats).map(([key,value])=>{
            return (
              renderStat(key, value)
            );
          })
        }
        </ul>
      }
      </div>
    </div>
  );
}
