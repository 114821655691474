import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useLogin from './useLogin';
import Navbar from './Navbar';
import Login from '../Login/Login';
import Dashboard from '../Dashboard/Dashboard';
import Setup from '../Setup/Setup';
import Company from '../Company/Company';
import Payload from '../Payload/Payload';
import Request from '../Request/Request';
import { User, UserAdd, UserEdit, UserDelete } from '../User';
import { Role, RoleAdd, RoleEdit, RoleDelete } from '../Role';
import { Location, LocationAdd, LocationEdit, LocationDelete } from '../Location';
import { Client, ClientAdd, ClientEdit, ClientDelete } from '../Client';

function App() {
  const { role, setRole, token, setToken } = useLogin();

  if(!token || !role) {
    return <Login setToken={setToken} setRole={setRole}/>
  }

  return (
    <div className="page">
      <Navbar token={token} role={role}/>
      <div className="main-content">
        <BrowserRouter>
          <Routes>
            
            <Route path="/" element={<Dashboard token={token}/>}/>
            <Route path="login" element={<Login setToken={setToken} setRole={setRole}/>}/>
            <Route path="dashboard" element={<Dashboard token={token}/>}/>
            <Route path="setup" element={<Setup token={token} role={role}/>}/>

            <Route path="user">
                <Route path=":id" element={<User token={token} role={role}/>}/>
                <Route path="add/:role_id" element={<UserAdd token={token} role={role}/>}/>
                <Route path="edit/:id" element={<UserEdit token={token} role={role}/>}/>
                <Route path="delete/:id" element={<UserDelete token={token} role={role}/>}/>
            </Route>

            <Route path="role">
                <Route path=":id" element={<Role token={token} role={role}/>}/>
                <Route path="add" element={<RoleAdd token={token} role={role}/>}/>
                <Route path="edit/:id" element={<RoleEdit token={token} role={role}/>}/>
                <Route path="delete/:id" element={<RoleDelete token={token} role={role}/>}/>
            </Route>

            <Route path="payload">
              <Route path=":id" element={<Payload token={token} role={role}/>}/>
              <Route path=":id/request">
                  <Route path=":id" element={<Request token={token}/>}/>
                </Route>
            </Route>

            <Route path="company">
              <Route path=":id" element={<Company token={token} role={role}/>}/>
              <Route path=":id/client">
                <Route path=":id" element={<Client token={token} role={role}/>}/>
                <Route path="add" element={<ClientAdd token={token} role={role}/>}/>
                <Route path="edit/:id" element={<ClientEdit token={token} role={role}/>}/>
                <Route path="delete/:id" element={<ClientDelete token={token} role={role}/>}/>
                <Route path=":id/location">
                  <Route path=":id" element={<Location token={token} role={role}/>}/>
                  <Route path="add" element={<LocationAdd token={token} role={role}/>}/>
                  <Route path="edit/:id" element={<LocationEdit token={token} role={role}/>}/>
                  <Route path="delete/:id" element={<LocationDelete token={token} role={role}/>}/>
                </Route>
              </Route>
            </Route>

          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;