/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Users from '../User/Users';

async function roleData(token, id) {
  return fetch(process.env.REACT_APP_API_URL + '/crud/roles/' + id,
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

export default function Role(props) {
  const [role, setRole] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();
  
  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await roleData(props.token, id);
      console.log(data);
      if (!data || data.errors) {
        await localStorage.removeItem("token");
        await localStorage.removeItem("role");
        window.location.replace('/login?from=' + window.location.pathname);
        return
      }
      if (!loaded) {
        setRole(data);
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, id])



  return(
    <div>
      {error ?
        <div className="uk-alert-danger" uk-alert="">
            <p>Role error!</p>
        </div>
      : null}
      {role ? 
        <div>
          <a href={'/setup'}>
            <button className="uk-button uk-button-default"><span uk-icon="arrow-left"></span> setup</button>
          </a>
          {props.role === 1 ?
            <div className="uk-width-1-1@s uk-width-1-4@m uk-child-width-1-1@s uk-child-width-1-2@m uk-margin-auto uk-align-right" uk-grid="">
              <a className="uk-button uk-button-default white uk-margin-remove uk-text-warning" href={'../edit/' + role.id}>EDIT</a>
              <a className="uk-button uk-button-default white uk-margin-remove uk-text-danger" href={'../delete/' + role.id}>DELETE</a>
            </div>
          : null}

          <h2>Role: {role.name}</h2>
          <Users roleId={id} token={props.token} role={props.role}/>
        </div>
      : null}
    </div>
  );
}
