/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

function renderPayloadDate(date) {
  if (!date && date !== 0) {
    return;
  }
  const now = new Date();
  const payload_date = new Date(date);
  const diff = now.getTime() - payload_date.getTime();
  const days_diff = diff / (1000 * 3600 * 24);
  if (days_diff <= 1) {
    return <span className='uk-text-success'>{payload_date.toLocaleString('en-US')} - Today</span>;
  }  
  const text = payload_date.toLocaleString('en-US') + ' ('+ Math.floor(days_diff) + ' days)';
  switch(true) {
    case (days_diff < 7):
      return <span className='uk-text-success'>{text}</span>;
    case (days_diff < 15):
      return <span className='uk-text-warning'>{text}</span>;
    case (days_diff < 30):
      return <span className='uk-text-danger'>{text}</span>;
    default:
      return <span className='uk-text-danger'>{text}</span>;
  }
}

function renderPayloadId(date, id) {
  const now = new Date();
  const payload_date = new Date(date);
  const diff = now.getTime() - payload_date.getTime();
  const days_diff = diff / (1000 * 3600 * 24);
  if (days_diff <= 1) {
    return <a className='uk-text-success' href={'/payload/' + id + '/'}>{payload_date.toLocaleString('en-US')} - Today</a>;
  }
  const text = payload_date.toLocaleString('en-US') + ' ('+ Math.floor(days_diff) + ' days)';
  switch(true) {
    case (days_diff < 7):
      return <a className='uk-text-success' href={'/payload/' + id + '/'}>{text}</a>;
    case (days_diff < 15):
      return <a className='uk-text-warning' href={'/payload/' + id + '/'}>{text}</a>;
    case (days_diff < 30):
      return <a className='uk-text-danger' href={'/payload/' + id + '/'}>{text}</a>;
    default:
      return <a className='uk-text-danger' href={'/payload/' + id + '/'}>{text}</a>;
  }
}

export default function LastPayload(props) {
  const featured = props.featured;
  if (!featured) {
    return;
  }
  if (props.column) {
    return <span>{renderPayloadId(featured.updated_at, featured.id)}</span>;
  }
  
  return(
    <div className="uk-card uk-card-default">
      <div className="uk-card-header">
          <h3 className="uk-card-title">Last payload</h3>
      </div>
      <div className="uk-card-body">
        <ul>
          <li>ID: <a href={'/payload/' + featured.id + '/'}>{featured.id}</a></li>
          <li>Date: {renderPayloadDate(featured.updated_at)}</li>
        </ul>
      </div>
    </div>
  );
}
