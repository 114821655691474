/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Pagination from '../Pagination/Pagination';
const LIMIT_PER_PAGE = 10;

async function usersData(token, page = 1, limit = LIMIT_PER_PAGE, role_id = '', search = '') {
  return fetch(process.env.REACT_APP_API_URL + '/crud/users/?' + 
    new URLSearchParams({
      'page': page,
      'limit': limit,
      'role_id': role_id,
      'search': search,
    }),
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

export default function Users(props) {
  const [users, setUsers] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit] = useState(LIMIT_PER_PAGE);
  const [roleId, setRole] = useState(props.roleId);
  const [error, setError] = useState(false);

  const handleSearchChange = async e => {
    setPage(1);
    setSearch(e.target.value);
  }

  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await usersData(props.token, page, limit, roleId, search);
      console.log(data);
      if (!data || data.errors) {
        setError(true)
        return
      }
      if (!loaded) {
        setUsers(data.users.data);
        setPage(data.users.meta.current_page)
        setLastPage(data.users.meta.last_page)
        setTotal(data.users.meta.total)
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [limit, page, props.token, roleId, search])

  return(
    <div id="users" className="uk-card uk-card-default">
      <div className="uk-card-header">
        <div uk-grid="">
          <div className="uk-width-1-1@s uk-width-expand@m">
            <h3 className="uk-card-title">Users ({total})</h3>
          </div>
          <div className="uk-width-1-1@s uk-width-1-3@m">
            <input className="uk-input" id="search" type="text" placeholder="User name" value={search} onChange={handleSearchChange}/>
          </div>
        </div>
      </div>

      {error ? 
        <div className="uk-alert-danger" uk-alert=""><p>Users error!</p></div>
      : null}

      {users ? 
        <div className="uk-card-body">
           
          <div uk-filter="target: .js-filter; animation: fade;">
          {props.roleId ? null :
            <ul className="uk-subnav uk-subnav-pill">
                <li className="uk-active" uk-filter-control=""><a href="#users" onClick={() => setRole('')}>ALL</a></li>
                <li uk-filter-control="[data-role='1']"><a href="#users" onClick={() => setRole(1)}>SuperAdmin</a></li>
                <li uk-filter-control="[data-role='2']"><a href="#users" onClick={() => setRole(2)}>Admin</a></li>
                <li uk-filter-control="[data-role='3']"><a href="#users" onClick={() => setRole(3)}>Viewer</a></li>
            </ul>}
          
          {total ?  
            <table className="uk-table uk-table-striped uk-table-hover">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th className="uk-table-expand">Name</th>
                        {props.roleId ? null : <th className="uk-table-expand">Role</th>}
                        <th></th>
                    </tr>
                </thead>
                <tbody className="js-filter">
                  {(users).map((user) =>
                      <tr key={user.id} data-role={user.role_id}>
                        <td><a href={'/user/' + user.id + '/'}>{user.id}</a></td>
                        <td>{user.name}</td>
                        {props.roleId ? null : <td><a href={'/role/' + user.role_id + '/'}>{user.role_name}</a></td>}
                        <td><a href={'/user/' + user.id + '/'}><span uk-icon="pencil"></span></a></td>
                      </tr>
                  )}
                </tbody>
            </table> 
          : <p>No results</p>}

          </div>
          <Pagination page={page} lastPage={lastPage} total={total} limit={limit} setPage={setPage}/>

          {props.roleId && props.role === 1 ? <a className="uk-button uk-button-default white uk-margin-remove uk-text-success" href={'/user/add/' + props.roleId}>ADD</a> : null}
        </div>
      : null}
    </div>
  );
}
