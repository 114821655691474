/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

async function sendData(token, id, data) {
  return fetch(process.env.REACT_APP_API_URL + '/crud/locations/' + id,
    {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

async function locationData(token, id) {
  return fetch(process.env.REACT_APP_API_URL + '/crud/locations/' + id,
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

export default function LocationEdit(props) {
  const [formData, setFormData] = useState({
    'profile_id': '',
    'profile_id_gup': '',
    'agent_id': '',
    'orb_profile': '',
    'billing': '',
    'email': '',
    'company_id': '',
    'client_id': '',
    'location_id': '',
    'clinic_name': '',
    'location_name': '',
    'first_name': '',
    'last_name': '',
    'full_name': '',
    'notes': '',
  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState(false);
  const { id } = useParams();

  const handleInputChange = async e => {
    console.log(e.target);
    const { id, value } = e.target;

    if(id == 'profile_id_gup'){
      formData['orb_profile'] = `https://www.onlinereviewbuilder.com/dashboard/customer/activity?businessId=${value}&clientId=${formData['agent_id']}`
    }
    if(id == 'agent_id'){
      formData['orb_profile'] = `https://www.onlinereviewbuilder.com/dashboard/customer/activity?businessId=${formData['profile_id_gup']}&clientId=${value}`
    }

    setFormData({ ...formData, [id]: value });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    console.log(formData);
    const response = await sendData(props.token, id, formData);
    if (!response) {
        setError(true);
        return
    }
    if (response.error) {
      setError(true);
      setResponse(response);
      console.log(response);
      return
    }
    setError(false); 
    setSuccess(true);
    setResponse(response);
    console.log(response);
  }

  useEffect(() => {
    let loaded = false;
    if (response) {
      return () => loaded = true;
    }

    const fetchData = async () => {
      const data = await locationData(props.token, id);
      console.log(data);
      if (!loaded) {
        setResponse(data);
        delete data['created_at'];
        delete data['updated_at'];
        setFormData(data);
      }
    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, response, id])

  return(
    <div>
      {error ?
        <div className="uk-alert-danger" uk-alert="">
            <p>Location error!</p>
            {response ? <p>{response.error}</p> : null}
        </div>
      : null}
      {success ?
        <div className="uk-alert-success" uk-alert="">
            <a className="uk-alert-close" uk-close=""></a>
            <p>Location edited successfully</p>
            <a href={'../' + id + '/'}>
              <button className="uk-button uk-button-default"><span uk-icon="arrow-left"></span> location</button>
            </a>
        </div>
      : null}
        <a href={'../' + id + '/'}>
          <button className="uk-button uk-button-default"><span uk-icon="arrow-left"></span> location</button>
        </a>
        <div className="uk-card uk-card-default uk-margin-top">
          <div className="uk-card-header">
              <h3 className="uk-card-title">Edit Location</h3>
          </div>
          <div className="uk-card-body">
            <form className="uk-form-horizontal" uk-grid="" onSubmit={handleSubmit}>

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="company_id">OMS</label>
                <div className="uk-form-controls">
                  <select className="uk-select" id="company_id" value={formData['company_id']} onChange={handleInputChange}>
                    <option value="">Please select...</option>
                    <option value="3">Sycle</option>
                    <option value="2">CounselEar</option>
                    <option value="1">Blueprint</option>
                  </select>
                </div>
              </div>

              {/*<div className="uk-width-1-1@s uk-width-1-2@m">*/}
              {/*  <label className="uk-form-label" htmlFor="profile_id">Profile</label>*/}
              {/*  <div className="uk-form-controls">*/}
              {/*      <input className="uk-input" id="profile_id" type="text" placeholder="Profile id" value={formData['profile_id']} onChange={handleInputChange}/>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="profile_id_gup">Business</label>
                <div className="uk-form-controls">
                  <input className="uk-input" id="profile_id_gup" type="number" placeholder="Business id" value={formData['profile_id_gup']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="agent_id">Agent</label>
                <div className="uk-form-controls">
                  <input className="uk-input" id="agent_id" type="number" placeholder="Agent id" value={formData['agent_id']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1">
                <label className="uk-form-label" htmlFor="orb_profile">ORB Profile</label>
                <div className="uk-form-controls">
                    <input className="uk-input" id="orb_profile" type="text" placeholder="Profile url" value={formData['orb_profile']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="billing">Billing</label>
                <div className="uk-form-controls">
                    <input className="uk-input" id="billing" type="text" placeholder="Billing url" value={formData['billing']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="email">Email</label>
                <div className="uk-form-controls">
                    <input className="uk-input" id="email" type="text" placeholder="Email" value={formData['email']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="location_id">Location ID</label>
                <div className="uk-form-controls">
                    <input className="uk-input" id="location_id" type="text" placeholder="Location id" value={formData['location_id']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="clinic_name">Clinic name</label>
                <div className="uk-form-controls">
                    <input className="uk-input" id="clinic_name" type="text" placeholder="Clinic name" value={formData['clinic_name']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="location_name">Location name</label>
                <div className="uk-form-controls">
                    <input className="uk-input" id="location_name" type="text" placeholder="Location name" value={formData['location_name']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="first_name">First name</label>
                <div className="uk-form-controls">
                    <input className="uk-input" id="first_name" type="text" placeholder="First name" value={formData['first_name']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="last_name">Last name</label>
                <div className="uk-form-controls">
                    <input className="uk-input" id="last_name" type="text" placeholder="Last name" value={formData['last_name']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="full_name">Full name</label>
                <div className="uk-form-controls">
                    <input className="uk-input" id="full_name" type="text" placeholder="Full name" value={formData['full_name']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor="notes">Notes</label>
                <div className="uk-form-controls">
                  <textarea className="uk-textarea" id="notes" type="text" placeholder="Notes" value={formData['notes']} onChange={handleInputChange}/>
                </div>
              </div>

              <div className="uk-width-1-1@s uk-width-1-1@m">
                <button className="uk-button uk-button-default" type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
    </div>
  );
}
