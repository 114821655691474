/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import LastPayload from '../Stats/LastPayload';
import Pagination from '../Pagination/Pagination';
const LIMIT_PER_PAGE = 10;

async function locationsData(token, page = 1, limit = LIMIT_PER_PAGE, company_id = '', client_id = '', location_id = '', search = '', order = 'desc') {
  return fetch(process.env.REACT_APP_API_URL + '/app/locations/?' + 
    new URLSearchParams({
      'page': page,
      'limit': limit,
      'company_id': company_id,
      'client_id': client_id,
      'location_id': location_id,
      'search': search,
      'order': order,
    }),
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

function renderRate(value) {
  if (!value && value !== 0) {
    return <span>N/A</span>;
  }
  
  switch(true) {
    case (value < 50):
      return <span className='uk-text-danger'>{value}%</span>;
    case (value < 75):
      return <span className='uk-text-warning'>{value}%</span>;         
    default:
      return <span className='uk-text-success'>{value}%</span>; 
  }
}

export default function Locations(props) {
  const [locations, setLocations] = useState(false);
  const [locationID, setLocationID] = useState('');
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('desc');
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit] = useState(LIMIT_PER_PAGE);
  const [error, setError] = useState(false);
  
  const handleSearchChange = async e => {
    setPage(1);
    setSearch(e.target.value);
  }

  const handleLocationIDChange = async e => {
    setPage(1);
    setLocationID(e.target.value);
  }

  function payloadOrder() {
    if (order === 'desc') {
      setOrder('asc')
    } else {
      setOrder('desc')
    }
  }

  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await locationsData(props.token, page, limit, props.company, props.client, locationID, search, order);
      console.log(data);
      if (!data || data.errors) {
        setError(true)
        return
      }
      if (!loaded) {
        setLocations(data.locations.data);
        setPage(data.locations.meta.current_page)
        setLastPage(data.locations.meta.last_page)
        setTotal(data.locations.meta.total)
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, page, limit, props.company, props.client, locationID, search, order])

  return(
    <div id="locations" className="uk-card uk-card-default">
      <div className="uk-card-header">
        <div uk-grid="">
          <div className="uk-width-1-1@s uk-width-expand@m">
            <h3 className="uk-card-title">Locations ({total})</h3>
          </div>
          <div className="uk-width-1-1@s uk-width-1-6@m">
            <input className="uk-input" id="location_id" type="text" placeholder="Location ID" value={locationID} onChange={handleLocationIDChange}/>
          </div>
          <div className="uk-width-1-1@s uk-width-1-3@m">
            <input className="uk-input" id="search" type="text" placeholder="Location name" value={search} onChange={handleSearchChange}/>
          </div>
        </div>
      </div>

      {error ? 
        <div className="uk-alert-danger" uk-alert=""><p>Locations error!</p></div>
      : null}

      {locations ? 
        <div className="uk-card-body">

          {total ?  
            <table className="uk-table uk-table-striped uk-table-hover">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Location ID</th>
                        <th className="uk-table-expand">Name</th>
                        <th className="uk-table-expand">Provider name</th>
                        <th className="uk-table-expand">Last payload {<a onClick={() => payloadOrder()} style={{ color: "#999", textDecoration: "none" }}>({order})</a>}</th>
                        <th className="uk-table-expand">GatherUp posts ( D | W | M | T )</th>
                        <th className="uk-table-expand">Success Rates ( D | W | M | T )</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                  {(locations).map((location) =>
                      <tr key={location.id}>
                        <td><a href={'/company/' + location.company_id + '/client/' + location.client_id + '/location/' + location.id + '/'}>{location.id}</a></td>
                        <td>{location.location_id}</td>
                        <td>{location.location_name}</td>
                        <td>{location.full_name}</td>
                        <td>{location.last_payload ? <LastPayload featured={location.last_payload} column={true}/> : 'N/A'}</td>
                        <td>{location.stats_daily.total_posts} | {location.stats_weekly.total_posts} | {location.stats_monthly.total_posts} | {location.stats_total.total_posts}</td>
                        <td>{renderRate(location.stats_daily.post_success_rate)} | {renderRate(location.stats_weekly.post_success_rate)} | {renderRate(location.stats_monthly.post_success_rate)} | {renderRate(location.stats_total.post_success_rate)}</td>
                        <td><a href={'/company/' + location.company_id + '/client/' + location.client_id + '/location/' + location.id + '/'}><span uk-icon="pencil"></span></a></td>
                      </tr>
                  )}
                </tbody>
            </table> 
          : <p>No results</p>}

          <Pagination page={page} lastPage={lastPage} total={total} limit={limit} setPage={setPage}/>

          {props.client && (props.role === 1 || props.role === 2) ? <a className="uk-button uk-button-default white uk-margin-remove uk-text-success" href={'location/add'}>ADD</a> : null}
        </div>
      : null}
    </div>
  );
}
