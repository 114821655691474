/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Pagination(props) {
  const page = props.page;
  const lastPage = props.lastPage;
  const total = props.total;
  const limit = props.limit;

  function setPage(page) {
    props.setPage(page)
  }
  function nextPage() {
    props.setPage(page + 1)
  }
  function prevPage() {
    props.setPage(page - 1)
  }

  return(
    <ul className="uk-pagination uk-flex-center" uk-margin="">
        {page !== 1 ? <li><a onClick={() => setPage(1)}><span uk-pagination-previous=""></span><span uk-pagination-previous=""></span></a></li> : null}
        {page !== 1 ? <li><a onClick={prevPage}><span uk-pagination-previous=""></span></a></li> : null}
        {[...Array(Math.ceil(total/limit)).keys()].map(pageNumber => {
          pageNumber = pageNumber + 1
          if (pageNumber >= page - 2 && pageNumber <= page + 2) {
            return <li key={pageNumber} className={page === pageNumber ? 'uk-active' : ''}><a onClick={() => setPage(pageNumber)}>{pageNumber}</a></li>
          } else {
            return null
          }
        })}
        
        {page !== lastPage ? <li><a onClick={nextPage}><span uk-pagination-next=""></span></a></li> : null}
        {page !== lastPage ? <li><a onClick={() => setPage(lastPage)}><span uk-pagination-next=""></span><span uk-pagination-next=""></span></a></li> : null}
    </ul>
  );
}
