/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Pagination from '../Pagination/Pagination';
const LIMIT_PER_PAGE = 10;

async function payloadsData(token, page = 1, limit = LIMIT_PER_PAGE, status = '', company_id = '', search = '') {
  return fetch(process.env.REACT_APP_API_URL + '/app/payloads/?' + 
    new URLSearchParams({
      'page': page,
      'limit': limit,
      'status': status,
      'company_id': company_id,
      'search': search,
    }),
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

function renderStatus(status) {
  switch(status) {
    case 'ERROR':
      return <span className='uk-text-danger'>ERROR</span>;   
    default:
      return <span className='uk-text-success'>SUCCESSFUL</span>; 
  }
}

function renderRate(value) {
  if (!value && value !== 0) {
    return <span>N/A</span>;
  }
  
  switch(true) {
    case (value < 50):
      return <span className='uk-text-danger'>{value}%</span>;
    case (value < 75):
      return <span className='uk-text-warning'>{value}%</span>;         
    default:
      return <span className='uk-text-success'>{value}%</span>; 
  }
}

export default function Payloads(props) {
  const [payloads, setPayloads] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit] = useState(LIMIT_PER_PAGE);
  const [status, setStatus] = useState('');
  const [error, setError] = useState(false);
  
  const handleSearchChange = async e => {
    setPage(1);
    setSearch(e.target.value);
  }

  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await payloadsData(props.token, page, limit, status, props.company, search);
      console.log(data);
      if (!data || data.errors) {
        setError(true)
        return
      }
      if (!loaded) {
        setPayloads(data.payloads.data);
        setPage(data.payloads.meta.current_page)
        setLastPage(data.payloads.meta.last_page)
        setTotal(data.payloads.meta.total)
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, page, limit, status, props.company, search])

  return(
    <div id="payloads" className="uk-card uk-card-default">
      <div className="uk-card-header">
        <div uk-grid="">
          <div className="uk-width-1-1@s uk-width-expand@m">
            <h3 className="uk-card-title">OMS Payloads ({total})</h3>
          </div>
          <div className="uk-width-1-1@s uk-width-1-3@m">
            <input className="uk-input" id="search" type="text" placeholder="Payload ID" value={search} onChange={handleSearchChange}/>
          </div>
        </div>
      </div>

      {error ? 
        <div className="uk-alert-danger" uk-alert=""><p>Payloads error!</p></div>
      : null}

      {payloads ? 
        <div className="uk-card-body">
          <div uk-filter="target: .js-filter; animation: fade;">
            <ul className="uk-subnav uk-subnav-pill">
                <li className="uk-active" uk-filter-control=""><a href="#payloads" onClick={() => setStatus('')}>ALL</a></li>
                <li uk-filter-control="[data-status='OK']"><a href="#payloads" onClick={() => setStatus('OK')}>SUCCESSFUL</a></li>
                <li uk-filter-control="[data-status='ERROR']"><a href="#payloads" onClick={() => setStatus('ERROR')}>ERROR</a></li>
            </ul>
          
          {total ?  
            <table className="uk-table uk-table-striped uk-table-hover">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th className="uk-table-expand">Status</th>
                        <th className="uk-table-expand">GatherUp posts</th>
                        <th className="uk-table-expand">Success Rates</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="js-filter">
                  {(payloads).map((payload) =>
                      <tr key={payload.id} data-status={payload.status}>
                        <td><a href={'/payload/' + payload.id + '/'}>{payload.id}</a></td>
                        <td style={{'verticalAlign': 'text-top'}}>{renderStatus(payload.status)} {payload.fixed_on ? <span uk-icon="check"></span> : null} {payload.parent_payload_id ? <span uk-icon="refresh"></span> : null}</td>
                        <td>{payload.stats_total.total_posts}</td>
                        <td>{renderRate(payload.stats_total.post_success_rate)}</td>
                        <td><a href={'/payload/' + payload.id + '/'}><span uk-icon="pencil"></span></a></td>
                      </tr>
                  )}
                </tbody>
            </table> 
          : <p>No results</p>}

          </div>
          <div><span uk-icon="check" style={{'verticalAlign': 'text-bottom'}}></span> Manually fixed</div>
          <div><span uk-icon="refresh" style={{'verticalAlign': 'text-bottom'}}></span> Retried</div>
          <Pagination page={page} lastPage={lastPage} total={total} limit={limit} setPage={setPage}/>
        </div>
      : null}
    </div>
  );
}
