/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Pagination from '../Pagination/Pagination';
const LIMIT_PER_PAGE = 10;

async function rolesData(token, page = 1, limit = LIMIT_PER_PAGE, search = '') {
  return fetch(process.env.REACT_APP_API_URL + '/crud/roles/?' + 
    new URLSearchParams({
      'page': page,
      'limit': limit,
      'search': search,
    }),
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

export default function Roles(props) {
  const [roles, setRoles] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit] = useState(LIMIT_PER_PAGE);
  const [error, setError] = useState(false);
  
  const handleSearchChange = async e => {
    setPage(1);
    setSearch(e.target.value);
  }

  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await rolesData(props.token, page, limit, search);
      console.log(data);
      if (!data || data.errors) {
        setError(true)
        return
      }
      if (!loaded) {
        setRoles(data.roles.data);
        setPage(data.roles.meta.current_page)
        setLastPage(data.roles.meta.last_page)
        setTotal(data.roles.meta.total)
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [limit, page, props.token, search])

  return(
    <div id="roles" className="uk-card uk-card-default">
      <div className="uk-card-header">
        <div uk-grid="">
          <div className="uk-width-1-1@s uk-width-expand@m">
            <h3 className="uk-card-title">Roles ({total})</h3>
          </div>
          <div className="uk-width-1-1@s uk-width-1-3@m">
            <input className="uk-input" id="search" type="text" placeholder="Role name" value={search} onChange={handleSearchChange}/>
          </div>
        </div>
      </div>

      {error ? 
        <div className="uk-alert-danger" uk-alert=""><p>Roles error!</p></div>
      : null}

      {roles ? 
        <div className="uk-card-body">
          {total ?  
            <table className="uk-table uk-table-striped uk-table-hover">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th className="uk-table-expand">Name</th>
                        <th className="uk-table-expand"># users</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="js-filter">
                  {(roles).map((role) =>
                      <tr key={role.id} data-role={role.role_id}>
                        <td><a href={'/role/' + role.id + '/'}>{role.id}</a></td>
                        <td>{role.name}</td>
                        <td>{role.users_count}</td>
                        <td><a href={'/role/' + role.id + '/'}><span uk-icon="pencil"></span></a></td>
                      </tr>
                  )}
                </tbody>
            </table> 
          : <p>No results</p>}

          <Pagination page={page} lastPage={lastPage} total={total} limit={limit} setPage={setPage}/>

          {/* <a className="uk-button uk-button-default white uk-margin-remove uk-text-success" href={'/role/add'}>ADD</a> */}
        </div>
      : null}
    </div>
  );
}
