/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Requests from '../Request/Requests';
import Stats from '../Stats/Stats';
import LastPayload from '../Stats/LastPayload';

async function locationData(token, id) {
  return fetch(process.env.REACT_APP_API_URL + '/app/location/' + id,
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

export default function Location(props) {
  const [location, setLocation] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();

  
  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await locationData(props.token, id);
      console.log(data);
      if (!data || data.errors) {
        await localStorage.removeItem("token");
        await localStorage.removeItem("role");
        window.location.replace('/login?from=' + window.location.pathname);
        return
      }
      if (!loaded) {
        setLocation(data.location);
        setFeatured(data.featured);
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, id])



  return(
    <div>
      {error ?
        <div className="uk-alert-danger" uk-alert="">
            <p>Location error!</p>
        </div>
      : null}
      {location ? 
        <div>
          <a href={'../../'}>
            <button className="uk-button uk-button-default"><span uk-icon="arrow-left"></span> client</button>
          </a>
          {props.role === 1 || props.role === 2 ?
            <div className="uk-width-1-1@s uk-width-1-4@m uk-child-width-1-1@s uk-child-width-1-2@m uk-margin-auto uk-align-right" uk-grid="">
              <a className="uk-button uk-button-default white uk-margin-remove uk-text-warning" href={'../edit/' + location.id}>EDIT</a>
              <a className="uk-button uk-button-default white uk-margin-remove uk-text-danger" href={'../delete/' + location.id}>DELETE</a>
            </div>
          : null}

          <h2>Location: {location.location_name} ({location.location_id})</h2>
          <div className="uk-card uk-card-default">
            <div className="uk-card-header">
                <h3 className="uk-card-title">{location.location_name}</h3>
            </div>
            <div className="uk-card-body">
              <p>Location ID: {location.location_id}</p>
              <p>OMS: {location.company_name}</p>
              <p>Clinic: {location.clinic_name}</p>
              <p>Full name: {location.full_name}</p>
              <p>Profile: {location.profile_id}</p>
              <p>Billing: <a href={location.billing}>{location.billing}</a></p>
              <p>Orb profile: <a href={location.orb_profile}>{location.orb_profile}</a></p>
              <p>Email: <a href={location.email}>{location.email}</a></p>
              <ul uk-accordion="multiple: true">
                <li>
                    <a className="uk-accordion-title" href="#">Notes</a>
                    <div className="uk-accordion-content">
                      <p>{location.notes}</p>
                    </div>
                </li>
              </ul>
            </div>
          </div>
          <hr></hr>
          <div className={"uk-grid-match uk-child-width-1-1@s uk-child-width-1-" + (featured ? '4@m' : '2@m')} uk-grid="">
            <div><Stats token={props.token} id={id} type={'location'} period={'Daily'}/></div>
            <hr className="uk-hidden@m"></hr>
            <div><Stats token={props.token} id={id} type={'location'} period={'Weekly'}/></div>
            <hr className="uk-hidden@m"></hr>
            <div><Stats token={props.token} id={id} type={'location'} period={'Monthly'}/></div>
            <hr className="uk-hidden@m"></hr>
            <div><Stats token={props.token} id={id} type={'location'} period={'Total'}/></div>
            <hr className="uk-hidden@m"></hr>
            {featured ? <div><LastPayload featured={featured}/></div> : null}
          </div>
          <hr></hr>
          <Requests location={id} token={props.token} role={props.role}/>
        </div>
      : null}
    </div>
  );
}
