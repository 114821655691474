/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

async function sendData(token, id, data) {
  return fetch(process.env.REACT_APP_API_URL + '/crud/clients/' + id,
    {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

async function clientData(token, id) {
  return fetch(process.env.REACT_APP_API_URL + '/crud/clients/' + id,
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

export default function ClientEdit(props) {
  const [formData, setFormData] = useState({
    'clinic_id': '',
    'company_id': '',
    'name': '',
    metadata:{
      'send_txt': true,
      'send_email': true,
    }

  });
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState(false);
  const { id } = useParams();

  const handleInputChange = async e => {
    console.log(e.target);
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  }

  const handleInputCheckChange = async e => {
    console.log(e.target);
    const { id, value, checked } = e.target;
    setFormData({ ...formData, metadata:{...formData.metadata, [id]: checked} });
    console.log(formData)

  }

  const handleSubmit = async e => {
    e.preventDefault();
    console.log(formData);
    const response = await sendData(props.token, id, formData);
    if (!response) {
        setError(true);
        return
    }
    if (response.error) {
      setError(true);
      setResponse(response);
      console.log(response);
      return
    }
    setError(false);
    setSuccess(true);
    setResponse(response);
    console.log(response);
  }

  useEffect(() => {
    let loaded = false;
    if (response) {
      return () => loaded = true;
    }

    const fetchData = async () => {
      const data = await clientData(props.token, id);
      console.log(data);
      if (!loaded) {
        setResponse(data);
        if(!data.metadata){
          data.metadata = {
            send_txt: true,
            send_email: true,
          }
        }else{
          data.metadata = JSON.parse(data.metadata)
        }
        delete data['created_at'];
        delete data['updated_at'];
        setFormData(data);
      }
    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, response, id])

  return(
    <div>
      {error ?
        <div className="uk-alert-danger" uk-alert="">
            <p>Client error!</p>
        </div>
      : null}
      {success ?
        <div className="uk-alert-success" uk-alert="">
            <a className="uk-alert-close" uk-close=""></a>
            <p>Client edited successfully</p>
            <a href={'../' + id + '/'}>See client</a>
        </div>
      : null}
        <a href={'../' + id + '/'}>
          <button className="uk-button uk-button-default"><span uk-icon="arrow-left"></span> client</button>
        </a>
        <div className="uk-card uk-card-default uk-margin-top">
          <div className="uk-card-header">
              <h3 className="uk-card-title">Edit Client</h3>
          </div>
          <div className="uk-card-body">
            <form className="uk-form-horizontal" uk-grid="" onSubmit={handleSubmit}>
            <div className="" style={{width:'100%'}}>
              <div style={{width:'70%',float:'left'}}>
                <div className="uk-card-body">
                  <p><b>Company</b></p>
                  <div className="uk-width-1-1@s uk-width-1-expand@m">
                    <label className="uk-form-label" htmlFor="company_id">OMS</label>
                    <div className="uk-form-controls">
                      <select className="uk-select" id="company_id" value={formData['company_id']} onChange={handleInputChange}>
                        <option value="">Please select...</option>
                        <option value="3">Sycle</option>
                        <option value="2">CounselEar</option>
                        <option value="1">Blueprint</option>
                      </select>
                    </div>
                  </div>
                  <div className="uk-width-1-1@s uk-width-1-expand@m">
                    <label className="uk-form-label" htmlFor="clinic_id">Clinic ID</label>
                    <div className="uk-form-controls">
                      <input className="uk-input" id="clinic_id" type="text" placeholder="Clinic ID" value={formData['clinic_id']} onChange={handleInputChange}/>
                    </div>
                  </div>
                  <div className="uk-width-1-1@s uk-width-1-expand@m">
                    <label className="uk-form-label" htmlFor="name">Name</label>
                    <div className="uk-form-controls">
                      <input className="uk-input" id="name" type="text" placeholder="Name" value={formData['name']} onChange={handleInputChange}/>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{width:'30%',float:'left'}}>
                <div className="uk-card-body">
                  <p><b>Request Settings</b></p>
                  <div className="uk-column-1-6" >
                    <div>
                      <span className="uk-column-span" style={{width: "25px"}} >TXT</span>
                    </div>
                    <div>
                      <label className="switch">
                        <input type="checkbox" id="send_txt" checked={(formData.metadata?.send_txt)?formData.metadata?.send_txt:false} onChange={handleInputCheckChange}/>
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="uk-column-1-6">
                    <div>
                      <span className="uk-column-span">eMail</span>
                    </div>
                    <div>
                      <label className="switch">
                        <input type="checkbox" id="send_email" checked={(formData.metadata?.send_email)?formData.metadata?.send_email:false} onChange={handleInputCheckChange}/>
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="uk-width-1-1@s uk-width-1-1@m">
              <button className="uk-button uk-button-default" type="submit">Submit</button>
            </div>
          </form>
          </div>
        </div>
    </div>
  );
}
