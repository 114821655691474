/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

async function configsData(token) {
  return fetch(process.env.REACT_APP_API_URL + '/app/configs',
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

async function sendConfigsData(token, data) {
  return fetch(process.env.REACT_APP_API_URL + '/app/configs',
    {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

export default function Configs(props) {
  const [configs, setConfigs] = useState(false);
  const [formConfigs, setFormConfigs] = useState();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleInputChange = async e => {
    const { id, value } = e.target;
    setFormConfigs({ ...formConfigs, [id]: value });
  }

  const handleSubmit = async e => {
    e.preventDefault();
    let form_configs = [];
    for (var [key, value] of Object.entries(formConfigs)) {
      const new_key = key.replace('config-', '');
      form_configs.push({id: new_key, value: value});
    }
    console.log(form_configs);
    const response = await sendConfigsData(props.token, {'configs': form_configs});
    if (!response || response.error) {
        setError(true);
        return
    }
    setError(false);
    setSuccess(true);
  }

  useEffect(() => {
    let loaded = false;
    if (formConfigs) {
      return () => loaded = true;
    }

    const fetchData = async () => {
      const data = await configsData(props.token);
      console.log(data);
      if (!data || data.errors) {
        await localStorage.removeItem("token");
        await localStorage.removeItem("role");
        window.location.replace('/login?from=' + window.location.pathname);
        return
      }
      if (!loaded) {
        setConfigs(data.configs);
        let form_configs = {};
        for (const config of data.configs) {
          form_configs['config-' + config.id] = config.value;
        }
        setFormConfigs(form_configs)
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, formConfigs])

  return(
    <div>
      {error ?
        <div className="uk-alert-danger" uk-alert="">
            <p>Security Settings error!</p>
        </div>
      : null}
      {success ?
        <div className="uk-alert-success" uk-alert="">
            <a className="uk-alert-close" uk-close=""></a>
            <p>Security Settings saved</p>
        </div>
      : null}      
      {configs ?
        <div className="uk-card uk-card-default">
          <div className="uk-card-header">
              <h3 className="uk-card-title">Security Settings</h3>
          </div>
          <div className="uk-card-body">
          <form className="uk-form-stacked" uk-grid="" onSubmit={handleSubmit}>
            {configs.map((config) =>
              <div key={config.id} className="uk-width-1-1@s uk-width-1-2@m">
                <label className="uk-form-label" htmlFor={'config-' + config.id}>{config.description}</label>
                <div className="uk-form-controls">
                    <input className="uk-input" id={'config-' + config.id} type="number" placeholder="In seconds" value={formConfigs['config-' + config.id]} onChange={handleInputChange}/>
                </div>
              </div>
            )}
            <div className="uk-width-1-1@s uk-width-1-3@m">
              <button className="uk-button uk-button-default" type="submit">Submit</button>
            </div>
          </form>
          </div>
        </div>
      : null}
    </div>
  );
}
