/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import './Dashboard.css';

async function userData(token) {
    return fetch(process.env.REACT_APP_API_URL + '/app/dashboard', {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
      .then(data => data.json())
      .catch(function() {
        return false;
    });
}

/*function renderPriority(priority) { // Notes
  switch(priority) {
    case "LOW":
      return 'warning';
    case "NORMAL":
      return '';      
    case "HIGH":
      return 'danger';
    default:
      return '';
  }
}*/

export default function Dashboard(props) {
  const [user, setUser] = useState(false);
  const [error, setError] = useState(false);
  const [companies, setCompanies] = useState([]);
  //const [notes, setNotes] = useState([]);

  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await userData(props.token);
      console.log(data);
      if (!data || data.errors) {
        await localStorage.removeItem("token");
        await localStorage.removeItem("role");
        window.location.replace('/login?from=' + window.location.pathname);
        return
      }
      if (!loaded) {
        setUser(data.name);
        //setNotes(data.notes);
        setCompanies(data.companies);
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token])



  return(
    <div>
      <h2>Dashboard</h2>
      {error ? <div className="uk-alert-danger" uk-alert="">
            <p>Dashboard error!</p>
        </div> : null}

      <div className="uk-card uk-card-default">
        <div className="uk-card-header">
          <h3 className="uk-card-title">
            {user ? 'Welcome ' + user : null}
          </h3>
        </div>
        <div className="uk-card-body">
          <div className="uk-margin-auto" uk-grid="">
            {companies.length ? 
              <div className="uk-width-1-1@s uk-width-1-3@m">
                <h3>OMS:</h3>
                <ul className="uk-list uk-list-striped">
                  {companies.map((company) =>
                    <li key={company.id}>
                      <a href={'/company/' + company.id  + '/'}>{company.name}</a>
                    </li>
                  )}
                </ul>
              </div>
            : null}
            {/* {notes.length ? 
            <div className="uk-width-1-1@s uk-width-2-3@m">
              <h3>NOTES:</h3>
                <ul className="uk-list">
                  {notes.map((note) =>
                    <li key={note.id}>
                      <div className={'uk-alert-' + renderPriority(note.priority)} uk-alert="">
                          <a className="uk-alert-close" uk-close=""></a>
                          <p className={'uk-alert-' + renderPriority(note.priority)}><b>{note.title}: </b>{note.content}</p>
                      </div>
                    </li>
                  )}
                </ul>
            </div>
            : null} */}
          </div>
        </div>
      </div>
    </div>
  );
}

