import React from 'react';
import './Navbar.css';

async function logOutUser(token) {
  return fetch(process.env.REACT_APP_API_URL + '/auth/logout', {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer '+ token,
      'Content-Type': 'application/json'
    }
  })
    .then(data => data.json())
    .catch(function() {
      return false;
  });
}

export default function Navbar(props) {
  const logOut = async () => {
    await logOutUser(props.token);
    await localStorage.removeItem("token");
    await localStorage.removeItem("role");
    window.location.replace('/');
  }

  return(
    <nav className="uk-navbar-container" uk-navbar="" uk-sticky="">
      <div className="uk-navbar-left">
          <a href="/" className="brand-name">
            <img alt="logo" src={process.env.PUBLIC_URL + '/medpb-logo.svg'} />
          </a>
          <ul className="uk-navbar-nav">
            <li>
              <a href="/dashboard"><span uk-icon="home"></span> Dashboard</a>
            </li>
            {props.role === 1 ?
              <li>
                <a href="/setup"><span uk-icon="settings"></span>Setup</a>
              </li>
            : null}
          </ul>

      </div>

      <div className="uk-navbar-right">

          <ul className="uk-navbar-nav">
              <li>
                <button className="uk-button uk-button-default" onClick={logOut}>Logout <span uk-icon="sign-out"></span></button>
              </li>
          </ul>

      </div>

    </nav>
  );
}