/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

async function userData(token, id) {
  return fetch(process.env.REACT_APP_API_URL + '/crud/users/' + id,
    {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer '+ token,
        'Content-Type': 'application/json'
      },
    })
    .then(data => data.json())
    .catch(function(error) {
      return error;
  });
}

export default function User(props) {
  const [user, setUser] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();
  
  useEffect(() => {
    let loaded = false;

    const fetchData = async () => {
      const data = await userData(props.token, id);
      console.log(data);
      if (!data || data.errors) {
        await localStorage.removeItem("token");
        await localStorage.removeItem("role");
        window.location.replace('/login?from=' + window.location.pathname);
        return
      }
      if (!loaded) {
        setUser(data);
      }

    }

    fetchData().catch(function(e) {
      console.log(e);
      setError(true);
    });

    return () => loaded = true;
  }, [props.token, id])



  return(
    <div>
      {error ?
        <div className="uk-alert-danger" uk-alert="">
            <p>User error!</p>
        </div>
      : null}
      {user ? 
        <div>
          <a href={'/setup'}>
            <button className="uk-button uk-button-default"><span uk-icon="arrow-left"></span> setup</button>
          </a>

          {props.role === 1 ?
            <div className="uk-width-1-1@s uk-width-1-4@m uk-child-width-1-1@s uk-child-width-1-2@m uk-margin-auto uk-align-right" uk-grid="">
              <a className="uk-button uk-button-default white uk-margin-remove uk-text-warning" href={'../edit/' + user.id}>EDIT</a>
              <a className="uk-button uk-button-default white uk-margin-remove uk-text-danger" href={'../delete/' + user.id}>DELETE</a>
            </div>
          : null}

          <h2>User: {user.name}</h2>
          <div className="uk-card uk-card-default">
            <div className="uk-card-header">
                <h3 className="uk-card-title">{user.name}</h3>
            </div>
            <div className="uk-card-body">
              <p>Email: {user.email}</p>
              <p>Role: <a href={'/role/' + user.role_id + '/'}>{user.role_name}</a></p>
              <ul uk-accordion="multiple: true">
                {user.notes ?              
                <li>
                    <a className="uk-accordion-title" href="#">Notes</a>
                    <div className="uk-accordion-content">
                      <p>{user.notes}</p>
                    </div>
                </li>
                : null} 
              </ul>
            </div>
          </div>
        </div>
      : null}
    </div>
  );
}
